import FormStep from './components/FormStep';

if($('#form-register').length > 0 || $('.data-show-form').length > 0) {
  new FormStep($('#form-register'), {
    document_cpf: {
      cpfBR: true,
    },
    document_cnpj: {
      cnpjBR: true,
    },
    bank_document:{
      validateDocument: true
    },
    password:{
      minlength: 8,
    },
    password_confirm : {
      minlength : 8,
      equalTo : "#password_id"
    }
  });

  $('#bank_document').bind("cut copy paste",function(e) {
    e.preventDefault();
  });
  //Script para trocar os campos (CPF, CNPJ, etc) dependendo do document_type (Tipo de Pessoa)
  $('*[data-show-if]').hide();
  $('*[data-show-if]').each(function () {
    var show_condition = $(this).data('show-if').split(':');
    var field_name = show_condition[0];
    var value = show_condition[1].toString().trim();
    var $toggle_element = $(this);
    var $trigger_element = $('*[name="' + field_name + '"]');

    $trigger_element.on('change', function () {
      if ($trigger_element.is('select')) {
        var current_value = $(this).find('option:selected').val().toString().trim();
      }
      else {
        var current_value = $(this).val().toString().trim();
      }
      if (current_value === value) {
        if ($(this).attr('type') === 'radio' && !$(this).is(':checked')) {
          $toggle_element.hide();
          return;
        }
        $toggle_element.show();
        return;
      }
      $toggle_element.hide();
    });

    var $current_selected = $('input[name="' + field_name + '"]:checked');
    if ($current_selected.length > 0) {
      $current_selected = $current_selected.attr('value').toString().trim();
    }
    if ($current_selected === value) {
      $toggle_element.show();
    }
  });
  //força o trigger para o data-show-if funcionar caso haja page reload
  $('select').trigger('change');


  //autocomplete de CEP
  $('.autocomplete-cep').keyup((e) => {

    let $cep = $(e.currentTarget);
    let $form = $cep.parents('.form-register');

    let cep = $cep.val();
    cep = cep.match(/\d+/g).join('');

    if (cep == '' || cep.length < 8) {
      return;
    }

    $.ajax({
      method: 'GET',
      url: 'https://viacep.com.br/ws/' + cep + '/json/',
      beforeSend: () => {
        $form.find('#address').val('...');
        $form.find('#neighborhood').val('...');
        $form.find('#city').val('...');
      },
      success: (response) => {

        if (response.erro) {
          return;
        }

        $form.find('#address').val(response.logradouro);
        $form.find('#neighborhood').val(response.bairro);
        $form.find('#city').val(response.localidade);
        $form.find('#uf').val(response.uf);
      },
      fail: function (response) {

      },
    });
  });

  // data-show-if para checkbox multiplos
  let $data_show_checkbox = $('*[data-show-if-checkbox]');
  $data_show_checkbox.hide();

  if ($('.data-show-form').length > 0){
    $('*[data-show-if-checkbox]').each(function(){
      if ($('#'+$(this).data('show-if-checkbox')).is(':checked')){
        $(this).show();
      }
    })
  }

  let array_checkboxes = [];
  $data_show_checkbox.each(function () {
    array_checkboxes.push($(this).data('show-if-checkbox'));
  });
  let $trigger_elm = $('.checkbox-trigger');
  $trigger_elm.click(function(){
    let $target_data = $(this).attr('id');
    let $found_elm = $('*[data-show-if-checkbox="'+$target_data+'"]');
    if ($(this).is(':checked')){
      $found_elm.fadeIn();
    }
    else {
      $found_elm.hide();
    }
  });

  //para dar highlight no mostrador de step correto ao retornar com validação fora do step1
  $(function(){
    $('.steps .step').each(function(){
      $(this).removeClass('active');
    });
    let $current_step = $('.form-register').find('.form-step.active').data('step');
    $('.steps .step[data-step="'+$current_step+'"]').addClass('active');
  });

  $('input').focus(function(){
    $(this).parent().find('.hide-error-message').addClass('d-block-important');
  });

}

$('.file-input').on("change", function () {
  var filename = $(this).val().replace(/C:\\fakepath\\/i, '');
  $(this).siblings().html(filename);
});

$.validator.addMethod( "validateDocument", function( value, element ) {
  "use strict";

  let selected_document = $('input[name="document_type"]:checked').val();
  let step1_document_field_value = $('#'+selected_document.toLowerCase()).val();
  return value == step1_document_field_value;

},'A conta bancaria precisa ser da mesma titularidade, insira o mesmo documento do seu cadastro.');
