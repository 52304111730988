//expertise system
import FormStep from "./components/FormStep";
require('../../node_modules/jquery-timepicker/jquery.timepicker');


function percentageRates() {
  $('#proposal_value').focusout(function () {
    if ($(this).val() > 1){
      $.ajaxSetup({
        headers: {
          'X-CSRF-TOKEN': $('input[name="_token"]').attr('value')
        }
      });
      $.ajax({
        url: "/percentage_rate",
        method: 'post',
        data: {
          value: $(this).val(),
          userType : $('#percentageRate').data('user')
        },
        success: function (result) {
          if (result) {
            let percentageRate = $('#percentageRate').data('rate');
            let withdrawTax = $('#withdrawTax').data('rate');
            if (percentageRate > 0) {
              $('#percentageRate').show();
              $('#percentageRate p').empty().append('<strong>Aviso:</strong> Ao completar a perícia, a plataforma cobrará uma taxa de ' + percentageRate + '% do valor da proposta, e R$'+withdrawTax +' da tarifa de saque. <br>O valor líquido total que será depositado na sua conta será de <strong class="text-blue-light">R$ ' + result + '.</strong>');
            }else {
              $('#percentageRate').show();
              $('#percentageRate p').empty().append('<strong>Aviso:</strong> Ao completar a perícia, a plataforma cobrará uma taxa de R$'+withdrawTax +' da tarifa de saque. <br>O valor líquido total que será depositado na sua conta será de <strong class="text-blue-light">R$ ' + result + '.</strong>');
            }
          }
        }
      });
      }else {
      $('#percentageRate').hide();
    }
  })
}


const body = document.querySelector("body");
if (body.classList.contains("create_expertise"))
{
  const formStep = new FormStep($('#form-create-expertise'));

  formStep.checkFormSubmitted();

  var slider = document.getElementById("range_slider");
  var output = document.getElementById("expertise_investment");
  output.innerHTML = 'R$ ' + slider.value; // Display the default slider value

// Update the current slider value (each time you drag the slider handle)
  slider.oninput = function() {
    localStorage.setItem(slider.name, this.value);
    output.innerHTML = 'R$ '+this.value;
  };

  $('select.define-optional-step').change(function(){
    localStorage.setItem($(this).attr('name'), $(this).val());
    let $optional_step = $(this).data('define-optional-step');
    if ($(this).find('option:selected').data('choice') == true){
      formStep.add_optional_step($optional_step);
    }
    else{
      //check if has a active step after current
      if ($('.form-step.optional-'+($optional_step+1).length)){
        let next_step_name = $('select[data-define-optional-step='+($optional_step+1)+']').attr('name');
        localStorage.removeItem(next_step_name);
        formStep.remove_optional_step($optional_step+1);
      }
      formStep.remove_optional_step($optional_step);
    }
  });


  document.addEventListener('step_updated', function (e) {
    let current_step = e.detail;
    if(current_step == $('.steps .last').data('step')){
      $('#create_expertise_button').html('Publicar');
    }
    else {
      $('#create_expertise_button').html('Seguinte');
    }
  }, false);

  function getPreviousSelectedOption(){
    //range_slider
    if(localStorage.getItem('expertise_investment')) {
      document.getElementById('expertise_investment').innerHTML = localStorage.getItem('expertise_investment');
    }
    //selects que definem steps opicionais
    $('select.define-optional-step').each(function(){
      let $optional_step = $(this).data('define-optional-step');
      if (localStorage.getItem($(this).attr('name')) != null)
      {
        let $current_option = $(this).find('option[value='+localStorage.getItem($(this).attr('name'))+']');
        $current_option.attr('selected', true);
        if ($current_option.data('choice') == true){
          formStep.add_optional_step($optional_step);
        }
        else{
          formStep.remove_optional_step($optional_step)
        }
      }
    });
  }

  $('.timepicker').timepicker({
    timeFormat: 'HH:mm:ss',
    interval: 60,
    minTime: '8',
    maxTime: '18',
    defaultTime: '12',
    startTime: '8',
    dynamic: false,
    dropdown: true,
    scrollbar: true,
    zindex: 100,
  });

  function showFormStepWithError($formId) {
    $($formId + ' .form-group').each(function () {
      if ($(this).hasClass('is-invalid')) {
        let $step = $(this).find('.form-control').data('form-step-number');
        let $form_step = $(this).parent().parent();
        $($formId).find('.form-step').hide();
        $form_step.css('display','block');
        if ($step) {
          var $steps_wrapper = $('.steps');
          $steps_wrapper.find('.step').each(function () {
            $(this).removeClass('active last');
          });
          $steps_wrapper.find('.step[data-step=' + $step + ']').removeClass('hide-step').addClass('active last');
        }
      }
    });
  }

  showFormStepWithError('#form-create-expertise');
  getPreviousSelectedOption();

  $('#choosen_expert').rules('add',{
      required: {
        depends: function(element) {
          return $("#choose_expert:checked")
        }
      }
  });

  document.addEventListener("DOMContentLoaded", function(event) {

      jQuery('#choosen_expert').select2({
        language: "pt-BR",
        placeholder: {
          text: 'Selecione o perito',
          id: ''
        },
        containerCssClass: 'form-control',
        width: '100%',
        allowClear: true
      }).on('select2:open', function(e){
        $('.select2-search__field').attr('placeholder', 'Buscar perito');
      });

  });
}


//submit proposal to expertise page
if (body.classList.value.includes("submit-proposal") || body.classList.value.includes("edit-proposal")){
  const maskMoney = require('../../node_modules/jquery-mask-plugin/dist/jquery.mask.min');
  $('#proposal_value').maskMoney().focus();

  percentageRates();

  new FormStep($('#submit-proposal'));
}

if (body.classList.value.includes('edit-expertise')){
  var slider = document.getElementById("range_slider");
  var output = document.getElementById("expertise_investment");
  output.innerHTML = 'R$ ' + slider.value; // Display the default slider value
  // Update the current slider value (each time you drag the slider handle)
  slider.oninput = function() {
    localStorage.setItem(slider.name, this.value);
    output.innerHTML = 'R$ '+this.value;
  };
}

if (body.classList.value.includes("edit-expertise")) {
  new FormStep($('#edit-expertise'))
}
if (body.classList.value.includes("edit-proposal")) {
  percentageRates();
  new FormStep($('#edit-proposal'))
}
