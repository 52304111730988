//fix the flash in HTML before CSS loading bug
document.getElementById("hideAll").style.display = "block";

window.onload = function()
{ document.getElementById("hideAll").style.display = "none"; }


//Classe para exibir o modal aberto
let modals = document.getElementsByClassName('modal');
for (let i = 0; i < modals.length; i++) {
  if ($('#' + modals[i].id).hasClass('show-modal-opened')) {
    $('#' + modals[i].id).modal('show');
  }
}

//Cria um método para abrir o modal de file upload clicando em uma ancora estilizada
$('a.trigger-file-upload').click(function () {
  $(this).parent().find('.trigger-file-upload-target').trigger('click');
});


//Botão Voltar (Go-Back)
$(document).ready(function(){
  if(localStorage.getItem('prevurls')){
    var prevurls_array = JSON.parse(localStorage.getItem('prevurls')).split(",");
    if(prevurls_array.length > 20){
      prevurls_array.splice(-1,1);
    }
    localStorage.setItem('prevurls', JSON.stringify(window.location.href + ',' + prevurls_array));
  } else {
      localStorage.setItem('prevurls', JSON.stringify(window.location.href));
  }
});
$(".go-back").click(function(){
  var prevurls =  JSON.parse(localStorage.getItem('prevurls')).split(",");
  for(var i=0; i<prevurls.length; i++) {
    if(window.location.href != prevurls[i]){
        window.location.href=prevurls[i];
        return;
    }
  }
  window.location.href=$("#referer").val();
});


//hover effect message buttons
$('a.btn-outline-blue-light').hover(function () {
  let current_elm = $(this)[0];
  let src = $(current_elm).find('img').prop('src');
  if(src) {
    if (src.includes('chat_baloon_blue_light.svg')) {
      $(current_elm).find('img').prop('src', src.replace('chat_baloon_blue_light', 'chat_baloon_black'));
    }
  }
}).mouseleave(function(){
  let current_elm = $(this)[0];
  let src = $(current_elm).find('img').prop('src');
  if (src) {
    $(current_elm).find('img').prop('src', src.replace('chat_baloon_black', 'chat_baloon_blue_light'));
  }
});

$('input').keyup(function(){
  if($(this).parent().hasClass('is-invalid')){
    $(this).parent().removeClass('is-invalid');
    $(this).siblings('.is-invalid').hide();
  }
});

$('.trigger-file-delete').click(function(){
  let file_id = $(this).data('file_id');
  let file_name = $(this).data('name');
  $('#formDeleteFile #file_id').val(file_id);
  $('#modalConfirmDelete #filename').empty().prepend(file_name);
});

$('.trigger-file-upload-target').on("change", function () {
  var filename = $(this).val().replace(/C:\\fakepath\\/i, '');
  if ($(this).hasClass('profile-picture')){
    $('#profile-validate').submit();
  }
  $(this).parent().find('.dz-message .text-center').html(filename);
});


// FormStep Previous Step global
import FormStep from "./components/FormStep";
const formStep = new FormStep($('.form-steps'));

if ($('.form-steps').length) {
  $('.form-steps').validate();
  $('#resume, .trigger-file-upload-target').rules('add', {
    accept: "application/pdf, application/vnd.oasis.opendocument.text, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    filesize: 31457280, // allow 30mb
    messages: {
      accept: "Formato inválido.",
      filesize: 'Envie um arquivo com o tamanho máximo de até 30mb.'
    },
  });
}

$('.go-back-step').click(function(){
  formStep.previousFormStep();
});


$('td:contains("R$")').addClass('currency-td');

//shows & hide archived chat messages
$('*[data-trigger="archived"]').click(function(){
  $('#archivedConversations, #msgsFromUsers, .notifications, .back-messages, .archived-title-wrapper').toggleClass('hide');
});
