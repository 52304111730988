// require('jquery-validation');

// $("#login-validate").validate({
//     rules: {
//         email: {
//             required: true,
//             email: true,
//         },
//         password: {
//             required: true,
//         }
//     },
//     messages: {
//         email: {
//             required: "Este campo é requerido.",
//             email: "Por favor, forneça um endereço de email válido.",
//         },
//         password: {
//             required: "Este campo é requerido.",
//         }
//     }
// });


$("#email-input").keyup(function () {
    $("#email-span").removeClass('invalid-feedback');
    $("#email-input").removeClass('is-invalid');
    $("#email-label").removeClass('is-invalid');
});

$("#password-input").keyup(function () {
    $("#password-span").removeClass('invalid-feedback');
    $("#password-input").removeClass('is-invalid');
    $("#password-label").removeClass('is-invalid');
});
