/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('jquery');
require('jquery-validation');
require('./bootstrap');
require('../../node_modules/jquery-validation/dist/additional-methods');
require('../../node_modules/jquery-validation/dist/localization/messages_pt_BR');
require('../../node_modules/dropzone/dist/dropzone');
require('../../node_modules/js-datepicker/dist/datepicker.min');
require('../../node_modules/select2/dist/js/select2.full.min');
require('../../node_modules/select2/dist/js/i18n/pt-BR');
require('moment');
require( 'datatables.net-bs4' );

require('./dataTables');
require('datatables.net-buttons');
require('./template-register');
require('./masks');
require('./template-login');
require('./components/autocomplete-cep');
require('./filters');
require('./common');
require('./expertises');
require('./components/validation');

