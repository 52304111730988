export default class FormStep {
  constructor(form, rules) {
    this.form = $(form);
    this.rules = rules;
    this.startValidation();
  }

  checkFormSubmitted(){
    //clear "localStorage fields cache" if form not submitted
    if(!localStorage.getItem('form_submitted')){
      localStorage.clear();
    }
    localStorage.removeItem('form_submitted');
  }

  add_optional_step($optional_step){
    //check if previous step has been activated
    if ($('.form-step[data-step='+($optional_step-1)+']').hasClass('optional-step')) {
      if ($('.form-step.optional-'+($optional_step-1)).data('step') == null){
        return
      }
    }
    var $steps_wrapper = $('.steps');
    $('.form-step.optional-step.optional-'+$optional_step).attr('data-step', $optional_step);
    $steps_wrapper.find('.step[data-step='+$optional_step+']').removeClass('hide-step').addClass('last');
    $steps_wrapper.find('.step[data-step='+($optional_step-1)+']').removeClass('last');
    $steps_wrapper.attr('data-steps',$optional_step);
    $('#create_expertise_button').html('Seguinte');
  }

  remove_optional_step($optional_step){
    var $steps_wrapper = $('.steps');
    $('.form-step.optional-step[data-step='+$optional_step+']').attr('data-step', '');
    $steps_wrapper.find('.step[data-step='+$optional_step+']').addClass('hide-step').removeClass('last');
    $steps_wrapper.find('.step[data-step='+($optional_step-1)+']').addClass('last');
    $('#create_expertise_button').html('Publicar');
  }

  startValidation() {
    $.validator.messages = {
      'required':'Campo obrigatório.',
      'email':'O campo precisa ser preenchido com um e-mail válido.',
      'minlength':'O campo precisa ser preenchido com 8 caracteres ou mais.',
      'cpfBR':'CPF inválido.',
      'cnpjBR':'CNPJ inválido.',
      'url': 'URL inválida.',
      'resume': 'Arquivo inválido.',
      'number': 'Somente números são aceitos neste campo.',
      'validateDocument': 'A conta bancaria precisa ser da mesma titularidade, insira o mesmo documento do seu cadastro.',
      'equalTo': "O valor precisa ser igual ao digitado no campo senha"
    };

    $.validator.addMethod('filesize', function (value, element, param) {
      return this.optional(element) || (element.files[0].size <= param)
    }, 'Envie um arquivo com o tamanho máximo de até {0}');

    this.formValidator = this.form.validate({
      focusInvalid: false,
      focusCleanup: true,
      errorClass: "is-invalid",
      errorElement: "small",
      rules: this.rules,
      highlight: function(element, errorClass) {
        $(element).parents('.form-group').addClass('is-invalid');
      },
      unhighlight: function(element, errorClass) {
        $(element).parents('.form-group').removeClass('is-invalid');
      },
      onfocusout: (input, event) => {
        $(event.currentTarget).valid();
      },
      submitHandler: () => {
        if(!this.nextFormStep() && localStorage.getItem('form_submitted') !== 'true') {
          $('#create_expertise_button').html('aguarde...').prop('disabled', true);
          setTimeout(() => {
            $('#create_expertise_button').html('Próximo').prop('disabled', false);
          }, "700");
          localStorage.setItem('form_submitted', true);
          this.submit();
        }
      }
    });
  }

  nextFormStep() {
    this.validateForm;
    let active_form_step = this.form.find('.form-step.active');
    let step = active_form_step.data('step');
    let next_form_step = this.form.find('.form-step[data-step="'+(step+1)+'"]');

    if(next_form_step.length == 0) {
      return false;
    }

    this.updateStep((step+1));

    active_form_step.slideUp(150, () => {
      active_form_step.removeClass('active');

      next_form_step.slideDown(200, () => {
        next_form_step.addClass('active');
      });
    });

    return true;
  }

  previousFormStep() {
    let active_form_step = this.form.find('.form-step.active');
    let step = active_form_step.data('step');
    let previous_form_step = this.form.find('.form-step[data-step="'+(step-1)+'"]');

    if(previous_form_step.length == 0) {
      window.history.back();
      return false;
    }

    this.updateStep((step-1));

    active_form_step.slideUp(150, () => {
      active_form_step.removeClass('active');

      previous_form_step.slideDown(200, () => {
        previous_form_step.addClass('active');
      });
    });

    return true;
  }

  updateStep(active_step) {

    let steps = this.form.find('.steps');
    steps.find('.step').each((i, e) => {
      let current = $(e).data('step');
      let current_step_image = $(e).data('step-image');
      if(current < active_step) {
        $(e).removeClass('active');
        $(e).addClass('passed');
      } else {
        if(current == active_step) {
          $('#side_image img.background_image').attr('src', current_step_image);
          $(e).removeClass('passed');
          $(e).addClass('active');
        } else {
          $(e).removeClass('passed');
          $(e).removeClass('active');
        }
      }
    });
    var event = new CustomEvent('step_updated', {'detail':active_step});
    document.dispatchEvent(event);
  }
}

export {FormStep}
