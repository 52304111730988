require('jquery-mask-plugin');
require('jquery-maskmoney/dist/jquery.maskMoney');

var maskBehavior = function (val) {
    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
  },
  options = {onKeyPress: function(val, e, field, options) {
      field.mask(maskBehavior.apply({}, arguments), options);
    }
  };

$('#phone, #diligence_telephone').mask(maskBehavior, options);
$('#cpf').mask('000.000.000-00');
$('#cnpj').mask('00.000.000/0000-00');
$('#zip_code').mask('00000-000');

$('#cc_number').mask('0000.0000.0000.0000');
$('#expire_date, #expire_year').mask('00');
$('#cvv').mask('000');

var options = {
  onKeyPress: function (cpf, ev, el, op) {
    var masks = ['000.000.000-000', '00.000.000/0000-00'];
    $('#cpfcnpj, .cpfcnpj').mask((cpf.length > 14) ? masks[1] : masks[0], op);
  }
}

$('#cpfcnpj, .cpfcnpj').length > 11 ? $('#cpfcnpj, .cpfcnpj').mask('00.000.000/0000-00', options) : $('#cpfcnpj, .cpfcnpj').mask('000.000.000-00', options);

$('.has-currency').maskMoney({
  prefix: 'R$ ',
  allowNegative: true,
  thousands: '.',
  decimal: ',',
  affixesStay: true // Mantem o Prefix/Suffix
});
