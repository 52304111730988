const moment = require('moment');

$date_picker_elm = document.getElementsByClassName('date-picker');
if ($date_picker_elm.length > 0) {
  const datepicker = require('js-datepicker');
  for (var i=0;i<$date_picker_elm.length;i++) {
    var dateToday = ($($date_picker_elm[i]).hasClass('is_filter') == true) ? new Date('2020-01-01') : new Date();
    const picker = datepicker($date_picker_elm[i], {
      minDate: dateToday,
      customDays: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      customMonths: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      onMonthChange: instance => {
        customizeDatePicker($datepickers);
      },
      onSelect: (input, date, instance) => {
        let classname = input.el.id;
        const inputText = input.el.dataset.text;
        var options = {year: 'numeric', month: 'numeric', day: 'numeric'};
        $('input.' + classname).attr('value', moment(Date.parse(date)).format());
        $('#' + input.el.id).html(inputText + ': ' + date.toLocaleString('pt-BR', options)).removeClass('btn-outline-blue-light border-blue-light').addClass('custom-input-backgrounded');
      }
    });
  }
  var $datepickers = document.getElementsByClassName('qs-datepicker-container');
  customizeDatePicker($datepickers);

  function customizeDatePicker($datepickers) {
    if ($datepickers) {
      $($datepickers).each(function () {
        $local_datepicker =  $(this).children('.qs-datepicker');
        $local_datepicker.addClass('card');
        $local_datepicker.children('.qs-squares').addClass('pt-0');
        $local_datepicker.children('.qs-controls').find('.custom_arrow_wrapper').remove();
        $local_datepicker.children('.qs-controls').addClass('flex-row-reverse pb-0').prepend('<div class="custom_arrow_wrapper"><div class="qs-arrow qs-left"></div><div class="qs-arrow qs-right"></div></div>');
        $local_datepicker.children('.qs-controls').children('.qs-month-year').wrap('<div class="custom_year_wrapper"></div>');
        $local_datepicker.find('.qs-controls > .qs-arrow').hide();
      });
    }
  }
}

$('.input-currency').click(function(){
  let elm_id = $(this).attr('id');
  $('#'+elm_id).hide();
  $('input.'+elm_id).show();
  $('input.'+elm_id).focus();
  $('input.'+elm_id).keyup(function(){
    $('.'+elm_id).focus();
  });
  $('.'+elm_id).focusout(function(){
    if ($(this).val() == 'R$ 0,00'){
      $('input.'+elm_id).hide();
      $('#'+elm_id).show();
    }
  });
});

$('#filterLabel .rounded-pill .close-button').click(function(){
  if($(this).parent().hasClass('dm')){
    $('input.data_movimentacao_de, input.data_movimentacao_ate').attr('value', '');
    $('input.data_de, input.data_ate').attr('value', '');
  }
  if($(this).parent().hasClass('vm')){
    $('input.valor_movimentacao_de, input.valor_movimentacao_ate').attr('value', '');
    $('input.valor_de, input.valor_ate').attr('value', '');
  }
  let $inputName = $(this).data('name');
  let $inputValue = $(this).data('value');

  //Filtro de Expertises
  $('input[name="'+$inputName+'[]"][value="'+$inputValue+'"]').prop('checked', false);

  $('#filterMovements').submit();
});

$('#filterMovements input.has-datepicker').each(function(){
  if($(this).val() != ''){
    $(this).parent().find('a').html('Data: ' + moment($(this).val() ).format('DD/MM/YYYY')).removeClass('btn-outline-blue-light border-blue-light').addClass('custom-input-backgrounded');
  }
});

$('#filterMovements input.has-currency').each(function(){
  if($(this).val() != ''){
    $(this).parent().find('a').hide();
    $(this).show();
  }
});

$('#filterMovements input[type="checkbox"]').change(function(){
  if(this.checked) {
    $(this).parent().removeClass('border-blue-light btn-outline-blue-light').addClass('custom-input-backgrounded');
  }
  else {
    $(this).parent().removeClass('custom-input-backgrounded ').addClass('border-blue-light btn-outline-blue-light');
  }
});

$('#filterMovements input:checked').each(function(){
  $(this).parent().removeClass('border-blue-light btn-outline-blue-light').addClass('custom-input-backgrounded');
});
