$(function(){
  $('.autocomplete-cep').keyup((e) => {

    let $cep = $(e.currentTarget);
    let $form = $cep.parents('.form-autocomplete');

    let cep = $cep.val();
    cep = cep.match(/\d+/g).join('');

    if (cep == '' || cep.length < 8) {
      return;
    }

    $.ajax({
      method: 'GET',
      url: 'https://viacep.com.br/ws/' + cep + '/json/',
      beforeSend: () => {
        $form.find('#address').val('...');
        $form.find('#neighborhood').val('...');
        $form.find('#city').val('...');
      },
      success: (response) => {

        if (response.erro) {
          return;
        }

        $form.find('#address').val(response.logradouro);
        $form.find('#neighborhood').val(response.bairro);
        $form.find('#city').val(response.localidade);
        $form.find('#uf').val(response.uf);
      },
      fail: function (response) {

      },
    });
  });
});
