if ($('body').hasClass('checkout')) {

  function checkCreditCardOption() {
    return $('#credit_card').is(':checked');
  }

  var $requiredRule = {
    required: {
      depends: function (element) {
        return checkCreditCardOption();
      }
    }
  };

  $('#form-checkout').validate({
    focusInvalid: false,
    focusCleanup: true,
    errorClass: "is-invalid",
    errorElement: "small",
    rules: {
      cc_holder: $requiredRule,
      cc_number: $requiredRule,
      expire_date: $requiredRule,
      expire_year: $requiredRule,
      cvv: $requiredRule
    }
  });
}

if ($('body').hasClass('meu-perfil')) {
  let requiredRule = {required: true};

  $('#profile-validate').validate({
    focusInvalid: false,
    focusCleanup: true,
    errorClass: "is-invalid",
    errorElement: "small",
    rules: {
      name:requiredRule,
      phone:requiredRule,
      zip_code:requiredRule,
      address:requiredRule,
      number:requiredRule,
      neighborhood:requiredRule,
      city:requiredRule,
      uf:requiredRule
    },
    highlight: function(element, errorClass) {
      $(element).parents('.form-group').addClass('is-invalid');
    },
    unhighlight: function(element, errorClass) {
      $(element).parents('.form-group').removeClass('is-invalid');
    },
    onfocusout: (input, event) => {
      $(event.currentTarget).valid();
    }
  });
  $('#profile-validate').validate();
  $('#resume').rules('add', {
    accept: "application/pdf, application/vnd.oasis.opendocument.text, application/msword",
    filesize: 31457280, // allow 30mb
    messages: {
      accept: "Formato inválido.",
      filesize: 'Envie um arquivo com o tamanho máximo de até 30mb.'
    },
  });

}
